.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.27);
    /*backdrop-filter: blur(1px);*/
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;

}

.modal.active{
    opacity: 1;
    pointer-events: all;
    z-index: 1;
    max-width: 100%;
}

.modal__content {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid gray;
    height: 250px;
    width: 300px;
    transform: scale(0.5);
    transition: 0.25s all;
}
.modal__content.active {
    transform: scale(1);
}

.modal__remove {
    padding: 20px;
    border-radius: 8px;
    height: 80px;
    width: 300px;
    transform: scale(0.5);
    transition: 0.25s all;
    border: 1px solid gray;
}

.modal__remove.active {
    transform: scale(1);
}

.modal__getGoogleData {
    padding: 20px;
    border-radius: 16px;
    max-width: 100%;
    transform: scale(0.5);
    transition: 0.5s all;
}
.modal__getGoogleData.active {
    transform: scale(1);
}

.modal__showGoogleData {
    padding: 20px;
    border-radius: 8px;
    border: 1px solid gray;
    max-width: 66%;
    max-height: 66%;

    transform: scale(0.5);
    transition: 0.25s all;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
}
.modal__showGoogleData.active {
    transform: scale(1);
    overflow-y: auto;
    overflow-x: auto;
}