.app-drop-down-container {
    padding: 1px 6px;
    width: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.app-drop-down-container ul {
    justify-content: center;
    text-align: center;
    margin: 0;
    position: absolute;
    top: calc(100% + 5px);
    min-width: 100%;
    width: max-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    padding: 1px;
    border-radius: 3px;
    box-shadow: 0 0 2px #6e6e6e;
    list-style: none;
    z-index: 1;
}

@media only screen and (max-width: 1395px) {
    .app-drop-down-container ul {
        justify-content: center;
        text-align: center;
        margin: 0;
        position: center;
        top: calc(100% + 5px);
        min-width: 100%;
        width: 99%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 2px;
        padding: 1px;
        border-radius: 3px;
        box-shadow: 0 0 2px #6e6e6e;
        list-style: none;
        z-index: 1;
    }
}

.app-drop-down-container ul li{
    display: flex;
    background-color: #fcfcfc;
    text-align: justify;
    align-items: center;
    padding: 4px 4px;
    cursor: pointer;
    transition: background-color ease .2s;
    font-size: 15px;
    border-radius: 3px;
    box-shadow: 0 0 1px #949494;
}
.app-drop-down-container ul li:hover{
    background-color: #cbcaca;
}